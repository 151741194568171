import navigation from './mainNav';
import inputText from './inputText';
import inputEmail from './inputEmail';
import inputTel from './inputTel';
import inputNumber from './inputNumber';
import inputRadio from './inputRadio';
import inputCheckbox from './inputCheckbox';
import inputTime from './inputTime';
import inputDate from './inputDate';
import inputIDPassport from './inputIDPassport';
import inputPassword from './inputPassword';
import textarea from './textarea';
import select from './select';
import select_v2 from './select_v2';
import selectRelationship from './selectRelationship';
import selectThead from './selectThead';
import toggle from './toggle';
import toggle_v2 from './toggle_v2';
import toggleLarge from './toggleLarge';
import statusTag from './statusTag';
import modal from './modal';
import loader from './loader';
import toast from './toast';
import changePasswordBox from './changePasswordBox';
import progressSteps from './progressSteps';
import displayText from './displayText';
import sideNav from './sideNav';
import errorBox from './errorBox';

export default {
  navigation,
  inputText,
  inputEmail,
  inputTel,
  inputNumber,
  inputRadio,
  inputCheckbox,
  inputTime,
  inputDate,
  inputIDPassport,
  inputPassword,
  textarea,
  select,
  select_v2,
  selectRelationship,
  selectThead,
  toggle,
  toggle_v2,
  toggleLarge,
  statusTag,
  modal,
  loader,
  toast,
  changePasswordBox,
  progressSteps,
  displayText,
  sideNav,
  errorBox,
};
