import U from '../js/utils';

export default async (status) => {
  const tagClassName = status.replace(/\s/g, '');
  const div = `
    <div class="statusTag w-[140px] pl-4 py-[6px] flex items-center ${tagClassName}">
      <span class="h-3 w-3 bg-white rounded-full"></span>
      <span class="ml-2">${status}</span>
    </div>
  `;

  return U.createHTMLElementFromString(div);
};
