import comp from '../components/barrel';
import svg from '../js/svg';
import config from '../js/config';
import O from '../js/core';

export default {
  async prepare() {
    this.application_uuid = thsPage.vars.application_uuid;

    const vars = {};
    vars.application_uuid = this.application_uuid;
    this.recipe.methods.push(await O.mPOST('application/get-app-summary-v2', vars));
    this.recipe.methods.push(await O.mPOST('wills/get-will-withdrawal', vars));
  },
  async render() {
    const rec = this.recipe.methods;

    let data = {};
    if (rec.length) {
      data = await O.PREP(rec);
    }
    await this.loadPage(data);
  },
  async loadPage(data) {
    const appSummary = data['application/get-app-summary-v2'].data;
    const withdrawalList = data['wills/get-will-withdrawal'].data;

    const withdrawalPartyUUIDs = withdrawalList.map((entry) => entry.party_uuid);

    if (withdrawalPartyUUIDs.includes(appSummary.party1)) {
      appSummary.party1 = null;
    }

    if (withdrawalPartyUUIDs.includes(appSummary.party2)) {
      appSummary.party2 = null;
    }

    await this.buildRecordDiv(appSummary);
    await this.buildRecordList(withdrawalList);
  },
  async buildRecordList(data) {
    U.hide('listCard');

    const withdrawalListDiv = U.id('withdrawalListDiv');
    withdrawalListDiv.innerHTML = '';

    const table = U.divAtt('table', 'class', 'w-full');

    // HEADER
    const thead = U.divAtt('thead', 'class', 'w-full');
    const headRow = thead.insertRow();

    const h1 = headRow.insertCell(0);
    h1.innerHTML = 'Date';
    const h2 = headRow.insertCell(1);
    h2.innerHTML = 'Recorded By';
    const h3 = headRow.insertCell(2);
    h3.innerHTML = 'Party';
    const h4 = headRow.insertCell(3);
    h4.innerHTML = 'Withdrawn';
    const h5 = headRow.insertCell(4);
    h5.innerHTML = 'Date of Death';
    const h6 = headRow.insertCell(5);
    h6.innerHTML = 'Reason';
    const h7 = headRow.insertCell(6);
    h7.innerHTML = '';
    thead.appendChild(headRow);
    table.appendChild(thead);

    // Body
    const tbody = U.divAtt('tbody');
    table.appendChild(tbody);

    if (data.length) {
      data.forEach(async (row) => {
        const willRow = tbody.insertRow();
        const r1 = willRow.insertCell(0);
        r1.innerHTML = await U.formatDate(row.created_date);
        const r2 = willRow.insertCell(1);
        r2.innerHTML = `${row.created_by_f_name} ${row.created_by_s_name}`;
        const r3 = willRow.insertCell(2);
        r3.innerHTML = `${row.party_f_name} ${row.party_s_name}`;
        const r4 = willRow.insertCell(3);
        r4.innerHTML = await U.formatDate(row.withdrawal_date);
        const r5 = willRow.insertCell(4);
        r5.innerHTML = await U.formatDate(row.death_date);
        const r6 = willRow.insertCell(5);
        r6.innerHTML = row.withdrawal_reason;
        const r7 = willRow.insertCell(6);
        const download_onclick = `thsTab.downloadFile('${row.file_uuid}')`;
        const linkSVG = svg.download(download_onclick);
        r7.appendChild(linkSVG);
      });
      U.unhide('listCard');
    }

    withdrawalListDiv.appendChild(table);
  },
  async buildRecordDiv(appSummary) {

	const recordWithdrawalDiv = U.id('recordWithdrawalDiv');
    recordWithdrawalDiv.innerHTML = '';

    if (!appSummary.party1 && !appSummary.party2) {
		U.hide('withdrawalCard');
      return;
    }

    const div = U.divAtt('div');

    if (appSummary.party1) {
      const party1Tgl = await comp.toggle_v2({
        elID: 'party1Tgl',
        isChecked: false,
        labelName: `Party 1 - ${appSummary.party1_f_name} ${appSummary.party1_s_name}`,
        onchangeName: null,
        elValue: appSummary.party1,
      });

      div.appendChild(party1Tgl);
    }
    if (appSummary.party2) {
      const party2Tgl = await comp.toggle_v2({
        elID: 'party2Tgl',
        isChecked: false,
        labelName: `Party 2 - ${appSummary.party2_f_name} ${appSummary.party2_s_name}`,
        onchangeName: null,
        elValue: appSummary.party2,
      });
      div.appendChild(party2Tgl);
    }

    const textArea = await comp.textarea('notesDiv', '', 'Notes', true);
    const textArea2 = await comp.textarea('reasonDiv', '', 'Reason', true);
    const withdrawalDate = await comp.inputDate('withdrawalDate', null, 'Date of Withdrawal', true);
    const deathDate = await comp.inputDate('deathDate', null, 'Date of Death', true);
    const fileInputDiv = await this.fileInputDiv();

    div.appendChild(fileInputDiv);
    div.appendChild(withdrawalDate);
    div.appendChild(deathDate);
    div.appendChild(textArea);
    div.appendChild(textArea2);

    recordWithdrawalDiv.prepend(div);
	U.unhide('withdrawalCard');
  },
  async fileInputDiv() {
    const div = `                
				<!-- File Inputs -->
                <div class="space-y-4">
                  <!-- PDF Input -->
                  <div class="text-left">
                    <label for="pdfUpload" class="block text-gray-700 font-medium">Withdrawal Document:</label>
                    <div class="relative w-4/5 mx-auto mt-2">
                      <input id="pdfUpload" type="file" accept="application/pdf" class="block w-full px-4 py-2 text-gray-700 bg-white border border-gray-300 rounded-lg cursor-pointer focus:outline-none" required/>
                      <div class="absolute inset-y-0 right-4 flex items-center pointer-events-none">
                        <svg
                          xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="M21.2 15c.7-1.2 1-2.5.7-3.9-.6-2-2.4-3.5-4.4-3.5h-1.2c-.7-3-3.2-5.2-6.2-5.6-3-.3-5.9 1.3-7.3 4-1.2 2.5-1 6.5.5 8.8m8.7-1.6V21"/><path d="M16 16l-4-4-4 4"/>
                        </svg>
                      </div>
                      <span fb-for="pdfUpload"></span>
                    </div>
                  </div>
			`;

    return await U.createHTMLElementFromString(div);
  },
  async recordWillWithdrawal() {
    const tgls = document.querySelectorAll('.tglSwitch');
    const notesDiv = U.id('notesDiv');
    const reasonDiv = U.id('reasonDiv');
    const withdrawalDate = U.id('withdrawalDate');
    const deathDate = U.id('deathDate');
    const pdfUpload = U.id('pdfUpload');

    const checkedTogglesValues = Array.from(tgls)
      .filter((toggle) => toggle.checked)
      .map((toggle) => toggle.value);

    U.id('notesDiv').classList.remove('input-error');
    U.id('reasonDiv').classList.remove('input-error');
    U.id('withdrawalDate').classList.remove('input-error');
    U.id('deathDate').classList.remove('input-error');
    U.id('pdfUpload').classList.remove('input-error');

    tgls.forEach((tgl) => {
      tgl.classList.remove('input-error');
    });

    let isValid = true;

    if (!checkedTogglesValues.length) {
      console.log({tgls});
      tgls.forEach((tgl) => {
        tgl.classList.add('input-error');
      });
      isValid = false;
    }

    if (!notesDiv.value) {
      notesDiv.classList.add('input-error');
      isValid = false;
    }

    if (!reasonDiv.value) {
      reasonDiv.classList.add('input-error');
      isValid = false;
    }

    if (!withdrawalDate.value) {
      withdrawalDate.classList.add('input-error');
      isValid = false;
    }

    if (!deathDate.value) {
      deathDate.classList.add('input-error');
      isValid = false;
    }

    if (!pdfUpload.files[0]) {
      pdfUpload.classList.add('input-error');
      isValid = false;
    } else {
      if (pdfUpload.files[0].type !== 'application/pdf') {
        U.id('pdfUpload').classList.add('input-error');
        isValid = false;
      }
    }

    if (!isValid) {
      return;
    }

    const formData = new FormData();

    formData.append('party_uuid_str', JSON.stringify(checkedTogglesValues));
    formData.append('application_uuid', this.application_uuid);
    formData.append('uploadFile', pdfUpload.files[0]);
    formData.append('death_date', deathDate.value);
    formData.append('withdrawal_date', withdrawalDate.value);
    formData.append('withdrawal_notes', notesDiv.value);
    formData.append('withdrawal_reason', reasonDiv.value);

    const hdrs = await O.getAuthHdrs();

    try {
      const response = await fetch(`${config.apiURL}wills/ins-will-withdrawal`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${hdrs.token}`,
        },
        body: formData,
      });

      if (!response.ok) {
        console.log(response.status);
        if (response.status == 404) {
          throw new Error('No signed will found for application');
        } else {
          throw new Error('Withdrawal failed');
        }
      }

      this.render();
    } catch (error) {
      comp.errorBox('Error', error);
    }
  },
  async downloadFile(file_uuid) {
    const vars = {};
    vars.file_uuid = file_uuid;

    O.PREP([await O.mPOST('files/get-file-url', vars)])
      .then(async (res) => {
        window.open(res['files/get-file-url'].data.signedUrl, '_blank');
      })
      .catch((err) => {
        console.log(err);
      });
  },
};
