import comp from '../components/barrel';
import svg from '../js/svg';
import config from '../js/config';
import O from '../js/core';

export default {
	async prepare() {
		this.application_uuid = thsPage.vars.application_uuid;
		this.recipe.methods.push(
			await O.mPOST('wills/get-support-docs', { application_uuid: this.application_uuid })
		);
	},
	async render() {
		const rec = this.recipe.methods;

		let data = {};
		if (rec.length) {
			data = await O.PREP(rec);
		}
		await this.loadPage(data);
	},
	async loadPage(data) {
		const supportDocs = data['wills/get-support-docs'].data;
		await this.buildUploadDiv();
		await this.buildListDiv(supportDocs);
	},
	async buildUploadDiv() {
		const uploadDocumentDiv = U.id('uploadDocumentDiv');
		uploadDocumentDiv.innerHTML = '';

		const select = await comp.select_v2({
			elID: 'categoryList',
			ddData: [
				{ file_category: 'APPLICATION FORM', display_category: 'Application form' },
				{ file_category: 'AMMENDED APPLICATIN FORM', display_category: 'Amended application form' },
				{ file_category: 'SUPPORT DOCUMENT', display_category: 'Support document' },
			],
			valueDataName: 'file_category',
			textDataName: 'display_category',
			labelName: 'Document Category',
			requiredYN: true,
		});

		const fileInput = this.buildFileInput()

		uploadDocumentDiv.appendChild(select);
		uploadDocumentDiv.appendChild(fileInput);
	},
	async buildListDiv(data) {
		U.hide('listCard');
		
		const documentListDiv = U.id('documentListDiv');
		documentListDiv.innerHTML = '';

		const table = U.divAtt('table', 'class', 'w-full');

		// HEADER
		const thead = U.divAtt('thead', 'class', 'w-full');
		const headRow = thead.insertRow();

		const h1 = headRow.insertCell(0);
		h1.innerHTML = 'Date';
		const h2 = headRow.insertCell(1);
		h2.innerHTML = 'File Name';
		const h3 = headRow.insertCell(2);
		h3.innerHTML = 'Uploaded By';
		const h4 = headRow.insertCell(3);
		h4.innerHTML = 'Category';
		const h5 = headRow.insertCell(4);
		h5.innerHTML = '';
		thead.appendChild(headRow);
		table.appendChild(thead);

		// Body
		const tbody = U.divAtt('tbody');
		table.appendChild(tbody);

		if (data.length) {
			data.forEach(async row => {
				const willRow = tbody.insertRow();
				const r1 = willRow.insertCell(0);
				r1.innerHTML = await U.formatDate(row.created_date);
				const r2 = willRow.insertCell(1);
				r2.innerHTML = row.file_name ;
				const r3 = willRow.insertCell(2);
				r3.innerHTML = `${row.f_name} ${row.s_name}`;
				const r4 = willRow.insertCell(3);
				r4.innerHTML = row.file_category;
				const h5 = willRow.insertCell(4);
				const download_onclick = `thsTab.downloadFile('${row.file_uuid}')`;
				const linkSVG = svg.download(download_onclick);
				h5.appendChild(linkSVG);
			});
			U.unhide('listCard');
		}

		documentListDiv.appendChild(table);
	},
	buildFileInput() {
		const div = `
			<div class="space-y-4">
				<!-- PDF Input -->
				<div class="text-left items-left">
				<label for="fileUpload" class="block text-gray-700 font-medium">Document:</label>
				<div class="relative w-full mx-auto mt-2">
					<input id="fileUpload" type="file" class="block w-full px-4 py-2 text-gray-700 bg-white border border-gray-300 rounded-lg cursor-pointer focus:outline-none" required/>
					<div class="absolute inset-y-0 right-4 flex items-center pointer-events-none">
					<svg
						xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="M21.2 15c.7-1.2 1-2.5.7-3.9-.6-2-2.4-3.5-4.4-3.5h-1.2c-.7-3-3.2-5.2-6.2-5.6-3-.3-5.9 1.3-7.3 4-1.2 2.5-1 6.5.5 8.8m8.7-1.6V21"/><path d="M16 16l-4-4-4 4"/>
					</svg>
					</div>
					<span fb-for="fileUpload"></span>
				</div>
			</div>
		`;

		return U.createHTMLElementFromString(div);
	},
	async uploadDocument() {

		let isValid = await U.validateForm('uploadDocumentDiv');

		const fileUpload = U.id('fileUpload').files[0];
		const categoryValue = U.id('categoryList').value;

		if (!fileUpload) {
			isValid = false;
		}

		if (!isValid) {
		  return;
		}


		const formData = new FormData();
		formData.append('uploadFile', fileUpload);
		formData.append('application_uuid', this.application_uuid);
		formData.append('file_category', categoryValue);

		const hdrs = await O.getAuthHdrs();

		// Send the form data using fetch
		try {
			const response = await fetch(`${config.apiURL}wills/upl-support-doc`, {
				method: 'POST',
				headers: {
					Authorization: `Bearer ${hdrs.token}`,
				},
				body: formData,
			});

			if (!response.ok) {
				throw new Error('File upload failed');
			}

			const result = await response.json();
			this.render();
		} catch (error) {
			console.error('Error uploading files:', error);
			O.handleFetchError(error);
		}
	},
	async downloadFile(file_uuid) {
		const vars = {};
		vars.file_uuid = file_uuid;

		O.PREP([await O.mPOST('files/get-file-url', vars)])
			.then(async res => {
				window.open(res['files/get-file-url'].data.signedUrl, '_blank');
			})
			.catch(err => {
				console.log(err);
			});
	},
};
