import comp from '../components/barrel';
import ADs_Heirs from './ADs_Heirs';

export default {
  tabsYN: 1,
  application_uuid: null,
  async prepare() {
    console.log('this.vars', this.vars);
    this.application_uuid = await this.vars.application_uuid;
    this.testament = await this.vars.testament;
  },
  async render() {
    const rec = this.recipe.methods;

    let data = {};
    if (rec.length) {
      data = await O.PREP(rec);
    }
    await this.loadPage(data);
  },
  async loadPage(data) {
    const activeTab = await comp.sideNav(this.tabs, this.testament);
    O.changeTab(activeTab);
  },

  async pageProgress(pageData, nextPrev) {
    //thsPage.pageProgress()
    // Eg. pageProgress: ------------
    // commenced: true;
    // complete: true;
    // html_text: 'ADs_Marriage';
    // next_page: 'ADs_ChildrenDetails';
    // prev_page: 'ADs_PersonalDetails';
    // short_text: 'Marriage Details';

    if (nextPrev) {
      await this.changeSideNavTab(pageData[`${nextPrev}`] || nextPrev);
    }

    const spanCircle = U.id(`${pageData.html_text}`).firstChild;
    spanCircle.className = '';
    // Set colours
    if (pageData.complete) {
      spanCircle.classList.add('complete');
      // spanCircle.classList.remove('incomplete');
    } else {
      if (pageData.commenced) {
        spanCircle.classList.add('incomplete');
      }
    }
  },
  async changeSideNavTab(pageID) {
    await O.changeSubTab(pageID);
    //Cheat to page --
    // await O.changeSubTab('ADs_Equity');
    window.scrollTo({top: 0});

    // Expand and collapse personal details party subsub tabs
    if (pageID === 'ADs_PersonalDetails') {
      U.unhide(`${pageID}_subSubTab`);
    } else {
      U.hide(`ADs_PersonalDetails_subSubTab`);
    }
  },
};
