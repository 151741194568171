import U from '../js/utils';

export default async (elID, ddData, valueDataName, textDataName, onchange, placeholder) => {
  return new Promise(async (resolve, reject) => {
    try {
      let placeholderMsg = placeholder || '';
      const opts = await U.buildOptsDD(ddData, valueDataName, textDataName, placeholderMsg);

      const div = `
    <div id="${elID}Div" class="input-group">
      <select id="${elID}" name="${elID}" ${onchange ? `onchange="${onchange}"` : ''} >
        ${opts}
      </select>
    </div>
  `;

      resolve(U.createHTMLElementFromString(div));
      // const element = U.createHTMLElementFromString(div);
      //     return element;
    } catch (error) {
      console.error(error);
      reject(error);
    }
  });
};
