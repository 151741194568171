import svg from '../js/svg';
import comp from '../components/barrel';

export default {
  application_uuid: null,
  estateEntries: null,
  estateCats: null,
  async prepare() {
    this.application_uuid = thsPage.vars.application_uuid;

    const vars = {};
    vars.application_uuid = this.application_uuid;
    this.recipe.methods.push(await O.mPOST('application/get-estate-detail', vars));
    this.recipe.methods.push(await O.mPOST('application/get-app-summary', vars));
    this.recipe.methods.push(await O.mPOST('communal/estates', vars));
  },
  async render() {
    const rec = this.recipe.methods;

    let data = {};
    if (rec.length) {
      data = await O.PREP(rec);
    }
    await this.loadPage(data);
  },
  async loadPage(data) {
    const detail = data['application/get-estate-detail'].data;
    const estates = data['communal/estates'].data;

    const summary = data['application/get-app-summary'].data[0];
    console.log({estates, summary, detail});
    await this.buildFrame({estates, summary});
    await this.populateData({detail});
    this.estateEntries = {detail};
    this.estateCats = {estates};
  },
  async buildFrame({estates, summary}) {
    const {party1_user_uuid, party1_full_name, party2_user_uuid, party2_full_name} = summary;
    let partyArr = [{user_uuid: party1_user_uuid, full_name: party1_full_name}];
    let main_estate = U.id(`main_estate`);
    let cntr = 0;

    if (party2_user_uuid) {
      partyArr.push({user_uuid: party2_user_uuid, full_name: party2_full_name});
    }

    for (let party of partyArr) {
      const divMain = U.divAtt('div', 'class', 'mb-10');
      const name = U.divAtt('h5');
      name.innerHTML = `Party ${++cntr} - ${party.full_name}`;
      divMain.appendChild(name);

      for (let estate of estates) {
        const div = U.divAtt('div', 'class', 'mb-10');
        const h5 = U.divAtt('h5');
        h5.innerHTML = estate.short_text;

        const table = U.divAtt('table', 'id', `${party.user_uuid}_${estate.estate_uuid}`);
        table.classList.add('hide'); // Hide the table to start with
        const thead = U.divAtt('thead');
        const headRow = thead.insertRow();
        const descH = headRow.insertCell(0);
        descH.innerHTML = 'Description';
        const assH = headRow.insertCell(1);
        assH.innerHTML = 'Asset Rand Value';
        const liabH = headRow.insertCell(2);
        liabH.innerHTML = 'Liability Rand Value';
        const shareH = headRow.insertCell(3);
        shareH.innerHTML = 'Percentage of Share';
        const editH = headRow.insertCell(4);
        editH.innerHTML = 'Edit';
        const removeH = headRow.insertCell(5);
        removeH.innerHTML = 'Remove';

        const tbody = U.divAtt('tbody', 'id', `${estate.estate_uuid}_${party.user_uuid}`);

        thead.appendChild(headRow);
        table.appendChild(thead);
        table.appendChild(tbody);

        const addButt = U.divAtt('button');
        addButt.innerHTML = '+ Add Item';
        addButt.setAttribute('onclick', `thsSubTab.addItem('${estate.estate_uuid}','${party.user_uuid}','${estate.short_text}')`);

        div.appendChild(h5);
        div.appendChild(table);
        div.appendChild(addButt);
        divMain.appendChild(div);
      }
      main_estate.appendChild(divMain);
    }
  },
  async populateData({detail}) {
    for (let row of detail) {
      const tbody = U.id(`${row.dd_estate_uuid}_${row.testator_uuid}`);
      const estateRow = tbody.insertRow();

      const desc = estateRow.insertCell(0);
      desc.innerHTML = `${row.estate_desc}`;
      const ass_val = estateRow.insertCell(1);
      ass_val.innerHTML = `${row.ass_val}`;
      const liab_val = estateRow.insertCell(2);
      liab_val.innerHTML = `${row.liab_val}`;
      const share_val = estateRow.insertCell(3);
      share_val.innerHTML = row.share_val !== null ? row.share_val : 'not applicable';

      const editIcon = estateRow.insertCell(4);
      const edit_onclick = `thsSubTab.fetchEditItem('${row.application_estate_uuid}')`;
      const editSVG = svg.edit(edit_onclick);
      editIcon.appendChild(editSVG);

      const removeIcon = estateRow.insertCell(5);
      const onclick = `thsSubTab.removeItem('${row.application_estate_uuid}')`;
      const linkSVG = svg.trash(onclick);
      removeIcon.appendChild(linkSVG);

      tbody.appendChild(estateRow);
      U.unhide(`${row.testator_uuid}_${row.dd_estate_uuid}`);
    }
  },
  async removeItem(application_estate_uuid) {
    const vars = {};
    vars.application_estate_uuid = application_estate_uuid;

    O.PREP([await O.mPOST('application/rmv-estate-detail', vars)])
      .then(async (res) => {
        comp.toast(res['application/rmv-estate-detail'].status);
        O.changeSubTab('ADs_Equity');
      })
      .catch(console.error);
  },
  async addItem(estate_uuid, user_uuid, short_text) {
    const formHTML = await this.buildItemForm(short_text);
    const form = formHTML.outerHTML;

    const div = `
      <div
        id="itemBox"
        class="fixed inset-0 z-20 overflow-y-auto bg-opacity-50 bg-black ">

        <div class="card relative transform overflow-hidden">
        <h4>Add new item</h4>

        <div id="newItem">
        ${form}
        </div>

        <div
        class="flex justify-center items-center" >
        <button class="btn-outline mr-4" onclick="U.closeModal('itemBox')">Cancel</button>
        <button id="updPass-btn" onclick="thsSubTab.submitNewItem('${estate_uuid}', '${user_uuid}')">Add item</button>

      </div>`;

    const passBox = U.createHTMLElementFromString(div);
    document.body.prepend(passBox);
  },
  async buildItemForm(short_text, editData) {
    const div = U.divAtt('div', 'class', '');
    const grid1 = U.divAtt('div', 'class', 'res-grid-cols-1');
    const grid2 = U.divAtt('div', 'class', 'res-grid-cols-2');
    const title = U.divAtt('h6', 'class', 'text-primary');
    title.innerHTML = `Item Information`;

    let item_desc = await comp.inputText(`item_desc`, editData?.estate_desc || '', 'Description', 1);
    let item_ass = await comp.inputNumber(`item_ass`, editData?.ass_val || '', 'Asset Rand Value ', 0);
    let item_liab = await comp.inputNumber(`item_liab`, editData?.liab_val || '', 'Liability Rand Value ', 0);
    let item_share = await comp.inputNumber(`item_share`, editData?.share_val || '', 'Percentage of Share', 0);

    grid1.appendChild(item_desc);
    grid2.appendChild(item_ass);
    grid2.appendChild(item_liab);
    if (short_text == 'Fixed Property' || short_text == 'Business') {
      grid2.appendChild(item_share);
    }

    div.appendChild(grid1);
    div.appendChild(grid2);

    return div;
  },
  async submitNewItem(estate_uuid, user_uuid) {
    let isValid = await U.validateForm('newItem');
    if (!isValid) {
      return;
    }

    const vars = {};
    vars.application_uuid = this.application_uuid;
    vars.testator_uuid = user_uuid;
    vars.estate_uuid = estate_uuid;
    vars.estate_desc = U.id('item_desc').value;
    vars.ass_val = U.id('item_ass').value || 0;
    vars.liab_val = U.id('item_liab').value || 0;
    vars.share_val = U.id('item_share') ? U.id('item_share').value || 0 : null;
    console.log(vars);

    if (!vars.ass_val && !vars.liab_val) {
      U.id('item_ass').classList.add('input-error');
      U.id('item_liab').classList.add('input-error');

      const msg = 'Please note that a value for either Asset Rand Value or Liability Rand Value is required.';
      comp.errorBox('Missing input', msg);
      return;
    }

    O.PREP([await O.mPOST('application/ins-estate-detail', vars)])
      .then(async (res) => {
        U.closeModal('itemBox');
        const newItemData = res['application/ins-estate-detail'].data;
        comp.toast(res['application/ins-estate-detail'].status);

        O.changeSubTab('ADs_Equity');
      })
      .catch(console.error);
  },
  async fetchEditItem(app_estate_uuid) {
    console.log('this.estateEntries', this.estateEntries);
    const result = this.estateEntries.detail.find((item) => item.application_estate_uuid === app_estate_uuid);
    const estate = this.estateCats.estates.find((item) => item.estate_uuid === result.dd_estate_uuid);

    this.editItem(result.dd_estate_uuid, estate.short_text, result);
  },
  async editItem(estate_uuid, short_text, editData) {
    const formHTML = await this.buildItemForm(short_text, editData);
    const form = formHTML.outerHTML;

    const div = `
      <div
        id="itemBox"
        class="fixed inset-0 z-20 overflow-y-auto bg-opacity-50 bg-black ">

        <div class="card relative transform overflow-hidden">
        <h4>Edit item</h4>

        <div id="newItem">
        ${form}
        </div>

        <div
        class="flex justify-center items-center" >
        <button class="btn-outline mr-4" onclick="U.closeModal('itemBox')">Cancel</button>
        <button id="updPass-btn" onclick="thsSubTab.submitEditItem('${estate_uuid}', '${editData.application_estate_uuid}', '${editData.testator_uuid}')">Edit item</button>

      </div>`;

    const passBox = U.createHTMLElementFromString(div);
    document.body.prepend(passBox);
  },
  async submitEditItem(estate_uuid, application_estate_uuid, testator_uuid) {
    let isValid = await U.validateForm('newItem');
    if (!isValid) {
      return;
    }

    const vars = {};
    vars.application_estate_uuid = application_estate_uuid;
    vars.application_uuid = this.application_uuid;
    vars.testator_uuid = testator_uuid;
    vars.estate_uuid = estate_uuid;
    vars.estate_desc = U.id('item_desc').value;
    vars.ass_val = U.id('item_ass').value || 0;
    vars.liab_val = U.id('item_liab').value || 0;
    vars.share_val = U.id('item_share') ? U.id('item_share').value || 0 : null;

    if (!vars.ass_val && !vars.liab_val) {
      U.id('item_ass').classList.add('input-error');
      U.id('item_liab').classList.add('input-error');

      const msg = 'Please note that a value for either Asset Rand Value or Liability Rand Value is required.';
      comp.errorBox('Missing input', msg);
      return;
    }

    O.PREP([await O.mPOST('application/upd-estate-detail', vars)])
      .then(async (res) => {
        U.closeModal('itemBox');
        const newItemData = res['application/upd-estate-detail'].data;
        comp.toast(res['application/upd-estate-detail'].status);

        O.changeSubTab('ADs_Equity');
      })
      .catch(console.error);
  },
  async savePage(nextPrev) {
    const method = 'application/val-app-page';
    const vars = {};
    vars.application_uuid = this.application_uuid;
    vars.page_name = 'ADs_Equity';
    O.PREP([await O.mPOST(method, vars)])
      .then(async (res) => {
        comp.toast(res[method].status);
        thsPage.pageProgress(res[method].data, nextPrev);
      })
      .catch((err) => {
        console.log(err);
      });
  },
};
