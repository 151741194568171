export default async (tabs, testament) => {
  let activeTab = '';

  for (let tab in tabs) {
    const el = tabs[tab];

    if (el.activeTab) {
      activeTab = el.html_text;
    }

    // --------------------------------------
    // TABS
    const navItem = `
        <div id="${el.html_text}" class="tablinks" onclick="O.changeTab(this.id)">${el.short_text}</div>
      `;
    U.id('sideNav').appendChild(U.createHTMLElementFromString(navItem));

    // --------------------------------------
    // SUB TABS
    if (el.subMenu.length) {
      let subMenuList = '';
      let cntr = 0;

      for (let sub of el.subMenu) {
        cntr++;
        let subSubMenuList = '';

        // --------------------------------------
        // SUB-SUB TABS maybe???
        if (sub.subSubMenu && sub.subSubMenu.length) {
          let subSubItems = '';
          for (let subSub of sub.subSubMenu) {
            const subSubItem = `
          <div id="${sub.html_text}_party${subSub.short_text}"><span></span>${subSub.short_text}</div>
          `;
            // ACCORDION onclick here
            subSubItems += subSubItem;
            if (testament === 'Single') {
              break;
            }
          }

          const subSubDiv = `<div id="${sub.html_text}_subSubTab" class="subSubTabs hide">
          ${subSubItems}
          </div>`;
          subSubMenuList += subSubDiv;
        }

        const subItem = `
            <div id="${sub.html_text}" class="subTablinks" onclick="thsSubTab.savePage(this.id)"><span>${cntr}</span> ${sub.short_text}
            ${subSubMenuList}</div>
          `;
        // thsSubTab.savePage;
        //thsPage.changeSideNavTab(this.id)
        subMenuList += subItem;
      }

      const subMenu = `
        <div id="subTabs" class="subTabs hide">
          <div class="bar"></div>
          ${subMenuList}
        </div>
        `;

      U.id('sideNav').appendChild(U.createHTMLElementFromString(subMenu));
    }
  }

  return activeTab;
};
