import comp from '../components/barrel';
import svg from '../js/svg';

export default {
  application_uuid: null,
  party1_name: null,
  party2_name: null,
  async prepare() {
    this.application_uuid = thsPage.vars.application_uuid;
    const vars = {};
    vars.application_uuid = this.application_uuid;
    this.recipe.methods.push(await O.mPOST('application/get-devise-detail', vars));
  },
  async render() {
    const rec = this.recipe.methods;
    let data = {};
    if (rec.length) {
      data = await O.PREP(rec);
    }
    await this.loadPage(data);
  },
  async loadPage(data) {
    const parties = data[`application/get-devise-detail`].data.users[0];
    this.party1_name = parties.party1_name;
    this.party2_name = parties.party2_uuid ? parties.party2_name : null;

    // -------------------------------
    // Special Bequests
    const bequestsData = data[`application/get-devise-detail`].data.bequests;
    const wishData = data[`application/get-devise-detail`].data.wishes;
    this.buildBequestsTable(bequestsData);
    this.buildWishesTable(wishData);
  },
  buildBequestsTable(data) {
    const table = U.id('instructions');
    table.innerHTML = '';

    // HEADER
    const thead = U.divAtt('thead');
    const headRow = thead.insertRow();
    const nameH = headRow.insertCell(0);
    nameH.innerHTML = 'Number';
    const instructH = headRow.insertCell(1);
    instructH.innerHTML = 'Instructions';
    const editH = headRow.insertCell(2);
    editH.innerHTML = 'Edit';
    const removeH = headRow.insertCell(3);
    removeH.innerHTML = 'Remove';

    thead.appendChild(headRow);
    table.appendChild(thead);

    // BODY
    const tbody = U.divAtt('tbody');
    table.appendChild(tbody);
    let cntr = 1;
    if (data.length) {
      data.forEach(async (row) => {
        const childRow = tbody.insertRow();
        const name = childRow.insertCell(0);
        name.innerHTML = `${cntr}`;
        const instruct = childRow.insertCell(1);
        instruct.innerHTML = row.instruction || '';
        // --
        const editIcon = childRow.insertCell(2);
        const onclick1 = `thsSubTab.addBequest('${row.instruction}','${row.application_bequests_uuid}')`;
        const editlinkSVG = svg.edit(onclick1);
        editIcon.appendChild(editlinkSVG);
        // --
        const removeIcon = childRow.insertCell(3);
        const onclick = `thsSubTab.removeBequest('${row.application_bequests_uuid}')`;
        const linkSVG = svg.trash(onclick);
        removeIcon.appendChild(linkSVG);
        cntr++;
      });
    } else {
      //No bequest ---
      const bequestRow = tbody.insertRow();
      const noBequest = bequestRow.insertCell(0);
      noBequest.innerHTML = 'No bequests added.';
      noBequest.colSpan = 4;
    }
  },
  buildWishesTable(data) {
    const table = U.id('wishes');
    table.innerHTML = '';

    // HEADER
    const thead = U.divAtt('thead');
    const headRow = thead.insertRow();
    const nameH = headRow.insertCell(0);
    nameH.innerHTML = 'Number';
    const instructH = headRow.insertCell(1);
    instructH.innerHTML = 'Wishes';
    const editH = headRow.insertCell(2);
    editH.innerHTML = 'Edit';
    const removeH = headRow.insertCell(3);
    removeH.innerHTML = 'Remove';

    thead.appendChild(headRow);
    table.appendChild(thead);

    // BODY
    const tbody = U.divAtt('tbody');
    table.appendChild(tbody);
    let cntr = 1;
    if (data.length) {
      data.forEach(async (row) => {
        const childRow = tbody.insertRow();
        const name = childRow.insertCell(0);
        name.innerHTML = `${cntr}`;
        const instruct = childRow.insertCell(1);
        instruct.innerHTML = row.wish || '';
        // --
        const editIcon = childRow.insertCell(2);
        const onclick1 = `thsSubTab.addWish('${row.wish}','${row.application_wishes_uuid}')`;
        const editlinkSVG = svg.edit(onclick1);
        editIcon.appendChild(editlinkSVG);
        // --
        const removeIcon = childRow.insertCell(3);
        const onclick = `thsSubTab.removeWish('${row.application_wishes_uuid}')`;
        const linkSVG = svg.trash(onclick);
        removeIcon.appendChild(linkSVG);
        cntr++;
      });
    } else {
      //No bequest ---
      const bequestRow = tbody.insertRow();
      const noBequest = bequestRow.insertCell(0);
      noBequest.innerHTML = 'No Wishes added.';
      noBequest.colSpan = 3;
    }
  },
  async addBequest(bequestVal, bequestID) {
    const val = bequestVal ? bequestVal : '';
    const onclick = bequestVal ? `thsSubTab.updateBequest('${bequestID}')` : `thsSubTab.submitBequest()`;
    const formHTML = await comp.textarea('bequestInstructions', val, 'Instructions', 1);
    const form = formHTML.outerHTML;

    const div = `
    <div
      id="bequestBox"
      class="fixed inset-0 z-50 overflow-y-auto bg-opacity-50 bg-black ">

      <div class="card relative transform overflow-hidden">
      <h4>Add new instruction</h4>

      <div id="newBequest">
      ${form}
      </div>

      <div
      class="flex justify-center items-center" >
      <button class="btn-outline mr-4" onclick="U.closeModal('bequestBox')">Cancel</button>
      <button id="insBequest-btn" onclick="${onclick}">${bequestVal ? 'Save bequest' : 'Add bequest'}</button>

    </div>`;

    const passBox = U.createHTMLElementFromString(div);
    document.body.prepend(passBox);
  },
  async addWish(wishVal, wishID) {
    const val = wishVal ? wishVal : '';
    const onclick = wishVal ? `thsSubTab.updateWish('${wishID}')` : `thsSubTab.submitWish()`;
    const formHTML = await comp.textarea('wishInstructions', val, 'Wishes', 1);
    const form = formHTML.outerHTML;

    const div = `
    <div
      id="wishBox"
      class="fixed inset-0 z-50 overflow-y-auto bg-opacity-50 bg-black ">

      <div class="card relative transform overflow-hidden">
      <h4>Add new Wish</h4>

      <div id="newWish">
      ${form}
      </div>

      <div
      class="flex justify-center items-center" >
      <button class="btn-outline mr-4" onclick="U.closeModal('wishBox')">Cancel</button>
      <button id="insWish-btn" onclick="${onclick}" >${wishVal ? 'Save wish' : 'Add wish'}</button>

    </div>`;

    const passBox = U.createHTMLElementFromString(div);
    document.body.prepend(passBox);
  },
  async submitBequest() {
    U.loaderBtn(1, 'insBequest-btn');
    let isValid = await U.validateForm('newBequest');
    if (!isValid) {
      U.loaderBtn(0, 'insBequest-btn');
      return;
    }

    const vars = {};
    vars.application_uuid = this.application_uuid;
    vars.instruction = U.id('bequestInstructions').value;

    O.PREP([await O.mPOST('application/ins-bequest-detail', vars)])
      .then(async (res) => {
        U.closeModal('bequestBox');
        const newBequestData = res['application/ins-bequest-detail'].data.bequests;
        comp.toast(res['application/ins-bequest-detail'].status);
        console.log('newBequestData', newBequestData);
        //@mike return new list
        this.buildBequestsTable(newBequestData);
      })
      .catch(() => {
        console.error;
        U.loaderBtn(0, 'insBequest-btn');
      });
  },
  async submitWish() {
    U.loaderBtn(1, 'insWish-btn');
    let isValid = await U.validateForm('newWish');
    if (!isValid) {
      U.loaderBtn(0, 'insWish-btn');
      return;
    }

    const vars = {};
    vars.application_uuid = this.application_uuid;
    vars.wish = U.id('wishInstructions').value;

    O.PREP([await O.mPOST('application/ins-wish-detail', vars)])
      .then(async (res) => {
        U.closeModal('wishBox');
        const newBequestData = res['application/ins-wish-detail'].data.wishes;
        comp.toast(res['application/ins-wish-detail'].status);
        console.log('newWishData', newBequestData);
        //@mike return new list
        this.buildWishesTable(newBequestData);
      })
      .catch(() => {
        console.error;
        U.loaderBtn(0, 'insWish-btn');
      });
  },
  async removeBequest(bequestID) {
    const vars = {};
    vars.application_uuid = this.application_uuid;
    vars.application_bequests_uuid = bequestID;

    O.PREP([await O.mPOST('application/rmv-bequest-detail', vars)])
      .then(async (res) => {
        comp.toast(res['application/rmv-bequest-detail'].status);
        const newBequestData = res['application/rmv-bequest-detail'].data.bequests;
        this.buildBequestsTable(newBequestData);
        //@mike return list
        console.log('newBequestData', newBequestData);
      })
      .catch(console.error);
  },
  async removeWish(application_wishes_uuid) {
    const vars = {};
    vars.application_uuid = this.application_uuid;
    vars.application_wishes_uuid = application_wishes_uuid;

    O.PREP([await O.mPOST('application/rmv-wish-detail', vars)])
      .then(async (res) => {
        comp.toast(res['application/rmv-wish-detail'].status);
        const newBequestData = res['application/rmv-wish-detail'].data.wishes;
        this.buildWishesTable(newBequestData);
        //@mike return list
        console.log('newBequestData', newBequestData);
      })
      .catch(console.error);
  },
  async updateBequest(bequestID) {
    U.loaderBtn(1, 'insBequest-btn');
    let isValid = await U.validateForm('newBequest');
    if (!isValid) {
      U.loaderBtn(0, 'insBequest-btn');
      return;
    }

    const vars = {};
    vars.application_uuid = this.application_uuid;
    vars.application_bequests_uuid = bequestID;
    vars.instruction = U.id('bequestInstructions').value;

    O.PREP([await O.mPOST('application/ups-bequest-detail', vars)])
      .then(async (res) => {
        U.closeModal('bequestBox');
        const newBequestData = res['application/ups-bequest-detail'].data.bequests;
        comp.toast(res['application/ups-bequest-detail'].status);

        console.log('newBequestData', newBequestData);
        //@mike return new list
        this.buildBequestsTable(newBequestData);
      })
      .catch(() => {
        console.error;
        U.loaderBtn(0, 'insBequest-btn');
      });
  },
  async updateWish(wishID) {
    U.loaderBtn(1, 'insWish-btn');
    let isValid = await U.validateForm('newWish');
    if (!isValid) {
      U.loaderBtn(0, 'insWish-btn');
      return;
    }

    const vars = {};
    vars.application_uuid = this.application_uuid;
    vars.application_wishes_uuid = wishID;
    vars.wish = U.id('wishInstructions').value;

    O.PREP([await O.mPOST('application/ups-wish-detail', vars)])
      .then(async (res) => {
        U.closeModal('wishBox');
        const newWishData = res['application/ups-wish-detail'].data.wishes;
        comp.toast(res['application/ups-wish-detail'].status);
        this.buildWishesTable(newWishData);
      })
      .catch(() => {
        console.error;
        U.loaderBtn(0, 'insWish-btn');
      });
  },
  async savePage(nextPrev) {
    const method = 'application/val-app-page';
    const vars = {};
    vars.application_uuid = this.application_uuid;
    vars.page_name = 'ADs_Devise';
    O.PREP([await O.mPOST(method, vars)])
      .then(async (res) => {
        comp.toast(res[method].status);
        thsPage.pageProgress(res[method].data, nextPrev);
      })
      .catch((err) => {
        console.log(err);
      });
  },
};
