import svg from '../js/svg';
import U from '../js/utils';
import comp from '../components/barrel';


export default {
  application_uuid: null,
  coTrustee: null,
  party1_name: null,
  party2_name: null,
  async prepare() {
    this.application_uuid = thsPage.vars.application_uuid;
    const vars = {};
    vars.application_uuid = this.application_uuid;
    this.recipe.methods.push(await O.mPOST('application/get-trust-detail', vars));
  },
  async render() {
    const rec = this.recipe.methods;

    let data = {};
    if (rec.length) {
      data = await O.PREP(rec);
    }
    await this.loadPage(data);
  },
  async loadPage(data) {

    const { inheritance_in_trust_yn = false, inheritance_age = null, assets_to_guardian_yn = false, assets_to_trust_yn = false, trustee_yn = false } = data['application/get-trust-detail'].data;
    this.coTrustee = data['application/get-trust-detail'].data;
    const excludedAges = [18, 21, 25];
    const otherAgeText = 'other age';

    let card = U.id(`trust_section`);
    // -------------------------------
    // Co-Trustee
    let coTrusteeDiv = U.divAtt('div', 'class', 'mb-8');
    coTrusteeDiv.id = 'coTrusteeDiv';
    let cb2 = await comp.inputCheckbox('coTrustee', trustee_yn, 'Co-trustee');
    coTrusteeDiv.appendChild(cb2);
    card.appendChild(coTrusteeDiv);
    trustee_yn && this.buildNameTagAndBtn();
    // -------------------------------
    let inheritance = await comp.inputCheckbox('inh_minors', inheritance_in_trust_yn, 'Inheritance of minors in trust');
    card.appendChild(inheritance);
    // -------------------------------
    const ageDiv = U.divAtt('div');
    const heading = document.createElement('h6');
    heading.innerHTML = `Inheritance age`;
    ageDiv.appendChild(heading);

    const optionsArrr = ['18 years old', '21 years old', '25 years old', 'other age'];
    const inhAgeRadio = await comp.inputRadio('inh_age', optionsArrr, 1);
    ageDiv.appendChild(inhAgeRadio);

    let otherAge = excludedAges.includes(inheritance_age) ? `` : inheritance_age;

    const otherInhAge = await comp.inputNumber('inh_age_other', otherAge, 'Other inheritance age', 0);
    ageDiv.appendChild(otherInhAge);
    card.appendChild(ageDiv);

    // -------------------------------

    let guardian = await comp.inputCheckbox('inh_guardian', assets_to_guardian_yn, 'Movable assets to be kept by guardian');
    card.appendChild(guardian);

    let addedToTrust = await comp.inputCheckbox('inh_trust', assets_to_trust_yn, 'Movable assets to be sold and value added to trust');
    card.appendChild(addedToTrust);

    //-------------------------------
    //-------------------------------
    // coTrustee checkbox
    U.id(`coTrustee`).setAttribute('onchange', `thsSubTab.checkCos()`);
    //mark selected age and if
    let ageValue = excludedAges.includes(inheritance_age) ? `${inheritance_age} years old` : otherAgeText;
    let selectAge = document.querySelector(`input[name="inh_age"][value="${ageValue}"]`);
    if (inheritance_age != null) {
      selectAge.setAttribute('checked', true);
    }
    if (ageValue === otherAgeText && inheritance_age) {
      U.unhide('inh_age_otherDiv');
      U.id('inh_age_other').setAttribute("required", '');
    } else {
      U.hide('inh_age_otherDiv');
    };

    document.querySelectorAll('input[name="inh_age"]').forEach((radio) => {
      radio.setAttribute('onchange', 'thsSubTab.otherInhAge()');
    });
  },
  checkCos() {
    const cb = U.id(`coTrustee`);
    if (cb.checked !== true) {
      this.saveTrustPage(); //(auto save on page change?)
      // remove trustee name
      if (U.id(`nameTagcoTrusteeDiv`)) {
        U.id(`coTrusteeDiv`).removeChild(U.id(`nameTagcoTrusteeDiv`));
        U.id(`coTrusteeDiv`).removeChild(U.id(`btncoTrusteeDiv`));
      }
    } else {
      this.checkUser(coTrusteeDiv);
    }
  },
  async buildNameTagAndBtn() {
    const nameTag = U.divAtt('div', 'class', 'nameTag mt-4');
    nameTag.id = `nameTagcoTrusteeDiv`;
    
    const name= this.coTrustee.full_name|| `${this.coTrustee.h_user_f_name} ${this.coTrustee.h_user_s_name}`;

    //const name = `${this.coTrustee.h_user_f_name} ${this.coTrustee.h_user_s_name}`;
    const rel = this.coTrustee.rel_party1 ? this.coTrustee.rel_party1 : this.coTrustee.rel_party2;
    nameTag.innerHTML = `${name} - ${rel}`;

    const addHeir = U.divAtt('button', 'class', 'btn-icon btn-transparent');
    addHeir.setAttribute('onclick', `thsSubTab.checkCos()`);
    addHeir.id = `btncoTrusteeDiv`;
    const btnText = U.divAtt('span');
    btnText.innerHTML = 'Change nomination';
    const repeatSVG = await svg.repeat();
    addHeir.appendChild(repeatSVG);
    addHeir.appendChild(btnText);

    if (U.id(`nameTagcoTrusteeDiv`)) {
      U.id(`coTrusteeDiv`).removeChild(U.id(`nameTagcoTrusteeDiv`));
      U.id(`coTrusteeDiv`).removeChild(U.id(`btncoTrusteeDiv`));
    }
    U.id(`coTrusteeDiv`).appendChild(nameTag);
    U.id(`coTrusteeDiv`).appendChild(addHeir);
  },
  otherInhAge() {
    const selectedValue = document.querySelector('input[name="inh_age"]:checked').value;
    if (selectedValue === 'other age') {
      U.unhide('inh_age_otherDiv');
      U.id('inh_age_other').setAttribute("required", '');
    } else {
      U.hide('inh_age_otherDiv');
      U.id('inh_age_other').removeAttribute("required");
    }
  },
  async checkUser() {
    const inforObj = {
      relationship: [this.party1_name],
      dob: 1,
      email_address: 0,
      cell_nr: 0,
      textarea: 'Notes',
    };
    this.party2_name && inforObj.relationship.push(this.party2_name);
    const data = await U.addCivillian(inforObj);

    console.log('this is new user data', data);

    if (data) {
      // remove old trustee
      if (data !== 'Canceled' && U.id(`nameTagcoTrusteeDiv`)) {
        U.id(`coTrusteeDiv`).removeChild(U.id(`nameTagcoTrusteeDiv`));
        U.id(`coTrusteeDiv`).removeChild(U.id(`btncoTrusteeDiv`));
      }

      Object.keys(communal).length === 0 && (await U.getCommunalDropdowns());
      this.coTrustee = data;
      this.saveTrustPage(); //(will save on page change)
      this.buildNameTagAndBtn();
    } else {
      // remove old trustee
      if (U.id(`nameTagcoTrusteeDiv`)) {
        U.id(`coTrusteeDiv`).removeChild(U.id(`nameTagcoTrusteeDiv`));
        U.id(`coTrusteeDiv`).removeChild(U.id(`btncoTrusteeDiv`));
      }
      //cancelled adding new trustee
      U.id(`coTrustee`).checked = false;
    }
  },
  async saveTrustPage() {
    return new Promise(async (resolve, reject) => {
      try {
        const selectedValue = document.querySelector('input[name="inh_age"]:checked');

        let age = selectedValue ? selectedValue.value.split(' ')[0] : null;
        const trusteeyn = U.id('coTrustee').checked;

        const vars = {};
        const inh_age_exists=document.querySelector('input[name="inh_age"]:checked');

        if(inh_age_exists!==null){
          const selectedValue = document.querySelector('input[name="inh_age"]:checked').value||null;
          let age = selectedValue.split(' ');
          vars.inheritance_age = age[0] === 'other' ? U.id('inh_age_other').value : age[0];
        }
        else{
          vars.inheritance_age=null;
        }
       
        vars.application_uuid = this.application_uuid;
        vars.inheritance_in_trust_yn = U.id('inh_minors').checked;
        vars.inheritance_age = age === 'other' ? U.id('inh_age_other').value : age;
        vars.assets_to_guardian_yn = U.id('inh_guardian').checked;
        vars.assets_to_trust_yn = U.id('inh_trust').checked;
        vars.trustee_yn = false;
       
        // ----------------------------------------
        if (trusteeyn) {
          this.coTrustee.user_uuid && (this.coTrustee.trustee_uuid = this.coTrustee.user_uuid);
          if (this.coTrustee.trustee_uuid) {
            vars.trustee
            vars.civillian_uuid = this.coTrustee.trustee_uuid;
            this.coTrustee.textarea && (this.coTrustee.notes = this.coTrustee.textarea);
            vars.notes = this.coTrustee.notes;
            vars.trustee_yn = true;
          } else {
            U.id('coTrustee').checked = false;
          }

        }

        // if (!vars.inheritance_age) {
        //   return comp.errorBox('Info', 'Please insert a value for inheritance age.');
        // }

        await O.PREP([await O.mPOST('application/ups-trust-detail', vars)])
        .then(async (res) => {
          const msg = res['application/ups-trust-detail'].status;
          comp.toast(msg);

          this.coTrustee = res['application/ups-trust-detail'].data;
          // this.coTrustee.trustee_yn && this.buildNameTagAndBtn();
          resolve();
        });
      } catch (error) {
        console.error(error);
        reject(error);
      }
    });
  },
  async savePage(nextPrev) {
    await this.saveTrustPage();
    const method = 'application/val-app-page';
    const vars = {};
    vars.application_uuid = this.application_uuid;
    vars.page_name = 'ADs_Trust';
    O.PREP([await O.mPOST(method, vars)])
      .then(async (res) => {
        comp.toast(res[method].status);
        thsPage.pageProgress(res[method].data, nextPrev);
      })
      .catch(err => { console.log(err) });
  },
};

