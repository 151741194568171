import U from '../js/utils';
export default async ({ elID, isChecked, labelName, onchangeName, elValue }) => {
	const div = `

    <div id="${elID}Div" class="">
        <label class="switch">
            <input class="tglSwitch" type="checkbox" id="${elID}" ${isChecked ? 'checked' : ''} ${
		onchangeName ? `onchange="${onchangeName}"` : ''
	} value="${elValue ? elValue : ''}">
            <span class="toggle round"></span>
        </label>
        <label id="${elID}_label" class="tgglLable" for="${elID}">${labelName}</label>
    </div>
  `;

	return U.createHTMLElementFromString(div);
};
