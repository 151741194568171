import comp from '../components/barrel';
import svg from '../js/svg';

export default {
  application_uuid: null,
  async prepare() {
    this.application_uuid = thsPage.vars.application_uuid;

    const vars = {};
    vars.application_uuid = this.application_uuid;
    this.recipe.methods.push(await O.mPOST('application/get-app-status-log', vars));
  },
  async render() {
    const rec = this.recipe.methods;

    let data = {};
    if (rec.length) {
      data = await O.PREP(rec);
    }
    await this.loadPage(data);
  },
  async loadPage(data) {
    const appHistoryData = data[`application/get-app-status-log`].data;

    // console.log('appHistoryData: ', appHistoryData);
    this.buildHistoryTable(appHistoryData)
  },
  async buildHistoryTable(data) {
    const table = U.id('appHistoryTable');
		table.innerHTML = '';

		// HEADER
		const thead = U.divAtt('thead', 'class', 'w-full');
		const headRow = thead.insertRow();

		const h1 = headRow.insertCell(0);
		h1.innerHTML = 'Date';
		const h2 = headRow.insertCell(1);
		h2.innerHTML = 'Status';
		const h3 = headRow.insertCell(2);
		h3.innerHTML = 'Person';
		const h4 = headRow.insertCell(3);
		h4.innerHTML = 'Log Summary';
		// const h5 = headRow.insertCell(4);
		// h5.innerHTML = 'Reference';
		const h6 = headRow.insertCell(4);
		h6.innerHTML = '';
		thead.appendChild(headRow);
		table.appendChild(thead);

		// Body
		const tbody = U.divAtt('tbody');
		table.appendChild(tbody);


		if (data.length) {
			data.forEach(async row => {
				const willRow = tbody.insertRow();
				const r1 = willRow.insertCell(0);
				r1.innerHTML = await U.formatDate(row.created_date);
				const r2 = willRow.insertCell(1);
        let statusText = await comp.statusTag(row.short_text);
        r2.appendChild(statusText);
				const r3 = willRow.insertCell(2);
				r3.innerHTML = `${row.f_name} ${row.s_name}`;
				const r4 = willRow.insertCell(3);
				r4.innerHTML = row.long_text;
				// const r5 = willRow.insertCell(4);
				// r5.innerHTML = row.file_reference;
				const r6 = willRow.insertCell(4);
				const download_onclick = ``;
				const linkSVG = svg.download(download_onclick);
				r6.appendChild(linkSVG);
				// r6.appendChild(await U.createHTMLElementFromString('<h4>Test</h4>'));
			});
		}
  }
};
