import comp from '../components/barrel';
import svg from '../js/svg';
import config from '../js/config';
import O from '../js/core';

export default {
  async prepare() {
    this.application_uuid = thsPage.vars.application_uuid;
    const vars = {};
    vars.application_uuid = this.application_uuid;
    this.recipe.methods.push(await O.mPOST('application/get-app-summary-v2', vars));
    this.recipe.methods.push(await O.mPOST('wills/get-app-summary-docs', vars));

  },
  async render() {
    const rec = this.recipe.methods;

    let data = {};
    if (rec.length) {
      data = await O.PREP(rec);
    }
    await this.loadPage(data);
  },
  async loadPage(data) {
    const dataAppDets = data['application/get-app-summary-v2'].data;
    const sumDocs = data['wills/get-app-summary-docs'].data;

	
    await this.buildSumDetails(dataAppDets);
    await this.buildDocList(sumDocs);
  },
  async buildSumDetails(data) {
    const {application_status, party1_f_name, party1_s_name, party2_f_name, party2_s_name, party2} = data;

    const status_row = U.id('status_row');
    status_row.innerHTML = '';
    let statusText = await comp.statusTag(application_status);
    status_row.appendChild(statusText);

    const partyDetails_row = U.id('partyDetails_row');
    partyDetails_row.innerHTML = '';

    const party1Div = U.divAtt('div', 'id', 'party1Div');
    const party1Label = U.divAtt('label', 'for', 'party1Div');
    party1Label.innerHTML = 'Party 1: ';
    const party1P = U.divAtt('p');
    party1P.innerHTML = `${party1_f_name} ${party1_s_name}`;
    party1Div.appendChild(party1Label);
    party1Div.appendChild(party1P);
    partyDetails_row.appendChild(party1Div);

    if (party2) {
      const party2Div = U.divAtt('div', 'id', 'party2Div');
      const party2Label = U.divAtt('label', 'for', 'party2Div');
      party2Label.innerHTML = 'Party 2: ';
      const party2P = U.divAtt('p');
      party2P.innerHTML = `${party2_f_name} ${party2_s_name}`;
      party2Div.appendChild(party2Label);
      party2Div.appendChild(party2P);
      partyDetails_row.appendChild(party2Div);
    }
  },
  async buildDocList(data) {
    U.hide('listDiv');

    const documentListDiv = U.id('documentListDiv');
    documentListDiv.innerHTML = '';

    const table = U.divAtt('table', 'class', 'w-full');

    // HEADER
    const thead = U.divAtt('thead', 'class', 'w-full');
    const headRow = thead.insertRow();

    const h1 = headRow.insertCell(0);
    h1.innerHTML = 'Date';
    const h2 = headRow.insertCell(1);
    h2.innerHTML = 'Generated By';
    const h3 = headRow.insertCell(2);
    h3.innerHTML = 'Version';
    const h4 = headRow.insertCell(3);
    h4.innerHTML = 'Download';
    thead.appendChild(headRow);
    table.appendChild(thead);

    // Body
    const tbody = U.divAtt('tbody');
    table.appendChild(tbody);

    if (data.length) {
      data.forEach(async (row) => {
        const willRow = tbody.insertRow();
        const r1 = willRow.insertCell(0);
        r1.innerHTML = await U.formatDate(row.created_date);
        const r2 = willRow.insertCell(1);
        r2.innerHTML = `${row.f_name} ${row.s_name}`;
        const r3 = willRow.insertCell(2);
        r3.innerHTML = row.application_uuid;
        const h4 = willRow.insertCell(3);
        const download_onclick = `thsTab.downloadFile('${row.file_uuid}')`;
        const linkSVG = svg.download(download_onclick);
        h4.appendChild(linkSVG);
      });
      U.unhide('listDiv');
    }

    documentListDiv.appendChild(table);
  },
  async downloadFile(file_uuid) {
    const vars = {};
    vars.file_uuid = file_uuid;

    O.PREP([await O.mPOST('files/get-file-url', vars)])
      .then(async (res) => {
        window.open(res['files/get-file-url'].data.signedUrl, '_blank');
      })
      .catch((err) => {
        console.log(err);
      });
  },
  async generateNewDocument() {
    const vars = {};
    vars.application_uuid = this.application_uuid;

    O.PREP([await O.mPOST('wills/gen-summary-document', vars)])
      .then(async () => {
        await this.render();
      })
      .catch((err) => {
        console.log(err);
      });
  },
};
