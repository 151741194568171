import {document} from 'postcss';

export default {
  application_uuid: null,
  editable: false,
  async prepare() {
    this.application_uuid = await thsPage.vars.application_uuid;
    const vars = {};
    vars.application_uuid = this.application_uuid;
    this.recipe.methods.push(await O.mPOST('application/get-application-detail', vars));
  },
  async render() {
    const rec = this.recipe.methods;

    let data = {};
    if (rec.length) {
      data = await O.PREP(rec);
    }
    await this.loadPage(data);
  },
  async loadPage(data) {
    const appProgressData = data['application/get-application-detail'].data;

    this.editable = appProgressData.editable;

    if (this.editable) {
      await this.buildSubMenu(appProgressData)
    } else {
      U.unhide('lockPage');
    }
  },
  async buildSubMenu(appProgressData) {

    U.unhide('subTabs');

    // Change progress status of the subtabs
    let pages = appProgressData.pages;
    if (pages) {
      Object.values(pages).forEach((page) => {
        thsPage.pageProgress(page);
      });
    }

    // // Change to active page
    const activePage = appProgressData.bookmark.page;
    thsPage.changeSideNavTab(activePage);
  },
};
